/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  CheckboxWidget,
  setData,
  getData,
  setValue,
  hideColumn,
  getValue,
  getGridValue,
  setGridValue,
  hide,
  goTo
} from "../../shared/WindowImports";

import "./PremiumDeductionsWRPurch.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import { Buffer } from 'buffer';
// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_PremiumDeductionsWRPurch(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES
  const compIdFromLS = sessionStorage.getItem('compId');
  const cropYearFromLS = JSON.parse(sessionStorage.getItem('year'));
  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "PremiumDeductionsWRPurch",
    windowName: "PremiumDeductionsWRPurch",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.PremiumDeductionsWRPurch",
    // START_USER_CODE-USER_PremiumDeductionsWRPurch_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Premiums/Deductions",
      scrCode: "PN1090G",
    },
    // END_USER_CODE-USER_PremiumDeductionsWRPurch_PROPERTIES
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    // btncolVendorSplits: {
    //   name: "btncolVendorSplits",
    //   type: "ButtonWidget",
    //   colName: "colVendorSplits",
    //   parent: "gridPremDeductWRP",
    //   Label: "...",
    //   CharWidth: "9",
    //   Height: "",
    //   Width: "",
    //   ReadOnly: true,
    //   ofTypeDomain: "d_boolean",
    //   // START_USER_CODE-USER_btncolVendorSplits_PROPERTIES

    //   // END_USER_CODE-USER_btncolVendorSplits_PROPERTIES
    // },
    btnOk: {
      name: "btnOk",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Ok",
      CharWidth: "8",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnOk_PROPERTIES

      // END_USER_CODE-USER_btnOk_PROPERTIES
    },
    btnPurchase: {
      name: "btnPurchase",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Purchase",
      CharWidth: "19",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPurchase_PROPERTIES

      // END_USER_CODE-USER_btnPurchase_PROPERTIES
    },
    chkbxcol: {
      name: "chkbxcol",
      type: "CheckBoxWidget",
      colName: "colChkbox",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbxcol_PROPERTIES

      // END_USER_CODE-USER_chkbxcol_PROPERTIES
    },
    colAmt1: {
      name: "colAmt1",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "amt1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmt1_PROPERTIES

      // END_USER_CODE-USER_colAmt1_PROPERTIES
    },
    colAmt2: {
      name: "colAmt2",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "amt2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmt2_PROPERTIES

      // END_USER_CODE-USER_colAmt2_PROPERTIES
    },
    colAmt3: {
      name: "colAmt3",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "amt3",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmt3_PROPERTIES

      // END_USER_CODE-USER_colAmt3_PROPERTIES
    },
    colAmt4: {
      name: "colAmt4",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "amt4",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAmt4_PROPERTIES

      // END_USER_CODE-USER_colAmt4_PROPERTIES
    },
    colAutoInd: {
      name: "colAutoInd",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "auto_ind",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colAutoInd_PROPERTIES

      // END_USER_CODE-USER_colAutoInd_PROPERTIES
    },
    colBeforeRecvInd: {
      name: "colBeforeRecvInd",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "before_recv_ind",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBeforeRecvInd_PROPERTIES

      // END_USER_CODE-USER_colBeforeRecvInd_PROPERTIES
    },
    colChkbox: {
      name: "colChkbox",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colChkbox_PROPERTIES

      // END_USER_CODE-USER_colChkbox_PROPERTIES
    },
    colInspectInd: {
      name: "colInspectInd",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "inspect_ind",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInspectInd_PROPERTIES

      // END_USER_CODE-USER_colInspectInd_PROPERTIES
    },
    colLstrVendorsP: {
      name: "colLstrVendorsP",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "LstrVendors_P",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLstrVendorsP_PROPERTIES

      // END_USER_CODE-USER_colLstrVendorsP_PROPERTIES
    },
    colOrigin: {
      name: "colOrigin",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "Origin",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colOrigin_PROPERTIES

      // END_USER_CODE-USER_colOrigin_PROPERTIES
    },
    colPamount: {
      name: "colPamount",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "Amount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPamount_PROPERTIES

      // END_USER_CODE-USER_colPamount_PROPERTIES
    },
    colPct1: {
      name: "colPct1",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "pct1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPct1_PROPERTIES

      // END_USER_CODE-USER_colPct1_PROPERTIES
    },
    colPct2: {
      name: "colPct2",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "pct2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPct2_PROPERTIES

      // END_USER_CODE-USER_colPct2_PROPERTIES
    },
    colPct3: {
      name: "colPct3",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "pct3",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPct3_PROPERTIES

      // END_USER_CODE-USER_colPct3_PROPERTIES
    },
    colPct4: {
      name: "colPct4",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "pct4",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPct4_PROPERTIES

      // END_USER_CODE-USER_colPct4_PROPERTIES
    },
    colPdCode: {
      name: "colPdCode",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "pd_code",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPdCode_PROPERTIES

      // END_USER_CODE-USER_colPdCode_PROPERTIES
    },
    colPdInd: {
      name: "colPdInd",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "pd_ind",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPdInd_PROPERTIES

      // END_USER_CODE-USER_colPdInd_PROPERTIES
    },
    colPremDeduct: {
      name: "colPremDeduct",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "Premium/Deduction",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPremDeduct_PROPERTIES

      // END_USER_CODE-USER_colPremDeduct_PROPERTIES
    },
    colPs1: {
      name: "colPs1",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "ps1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPs1_PROPERTIES

      // END_USER_CODE-USER_colPs1_PROPERTIES
    },
    colPs2: {
      name: "colPs2",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "ps2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPs2_PROPERTIES

      // END_USER_CODE-USER_colPs2_PROPERTIES
    },
    colPs3: {
      name: "colPs3",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "ps3",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPs3_PROPERTIES

      // END_USER_CODE-USER_colPs3_PROPERTIES
    },
    colPs4: {
      name: "colPs4",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "ps4",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colPs4_PROPERTIES

      // END_USER_CODE-USER_colPs4_PROPERTIES
    },
    colRateType: {
      name: "colRateType",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "rate_type",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRateType_PROPERTIES

      // END_USER_CODE-USER_colRateType_PROPERTIES
    },
    colRequiredInd: {
      name: "colRequiredInd",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "required_ind",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colRequiredInd_PROPERTIES

      // END_USER_CODE-USER_colRequiredInd_PROPERTIES
    },
    colSamount: {
      name: "colSamount",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "samount",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSamount_PROPERTIES

      // END_USER_CODE-USER_colSamount_PROPERTIES
    },
    colVendor1: {
      name: "colVendor1",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "vendor1",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor1_PROPERTIES

      // END_USER_CODE-USER_colVendor1_PROPERTIES
    },
    colVendor2: {
      name: "colVendor2",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "vendor2",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor2_PROPERTIES

      // END_USER_CODE-USER_colVendor2_PROPERTIES
    },
    colVendor3: {
      name: "colVendor3",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "vendor3",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor3_PROPERTIES

      // END_USER_CODE-USER_colVendor3_PROPERTIES
    },
    colVendor4: {
      name: "colVendor4",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "vendor4",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendor4_PROPERTIES

      // END_USER_CODE-USER_colVendor4_PROPERTIES
    },
    colVendorSplits: {
      name: "colVendorSplits",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      Label: "Vendor Splits",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colVendorSplits_PROPERTIES

      // END_USER_CODE-USER_colVendorSplits_PROPERTIES
    },
    txtColIsStatus: {
      name: "txtColIsStatus",
      type: "GridColumnWidget",
      parent: "grpbxPremDeductWRPurch",
      Label: "",
      HasLabel: false,
      isStatusColumn: true,
      Visible: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridcolumnwidget7_PROPERTIES

      // END_USER_CODE-USER_gridcolumnwidget7_PROPERTIES
    },
    txtIStatus: {
      name: "txtIStatus",
      type: "TextBoxWidget",
      colName: "txtColIsStatus",
      parent: "grpbxPremDeductWRPurch",
      Label: "",
      HasLabel: false,
      CharWidth: "48",
      Height: "",
      Width: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_textboxwidget8_PROPERTIES

      // END_USER_CODE-USER_textboxwidget8_PROPERTIES
    },

    txtColStatus: {
      name: "txtColStatus",
      type: "GridColumnWidget",
      parent: "gridPremDeductWRP",
      VisibleForLargeDesktop: false,
      VisibleForTabLandscape: false,
      VisibleForTabPotrait: false,
      isStatusColumn: true,
      HasLabel: false,
      Visible: false,
      Height: "",
      VisibleForMobile: false,
      Width: "",
      // START_USER_CODE-USER_txtColStatus_PROPERTIES

      // END_USER_CODE-USER_txtColStatus_PROPERTIES
    },

    txtStatus: {
      name: "txtStatus",
      type: "TextBoxWidget",
      colName: "txtColStatus",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      ReadOnly: true,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatus_PROPERTIES

      // END_USER_CODE-USER_txtStatus_PROPERTIES
    },

    gridPremDeductWRP: {
      name: "gridPremDeductWRP",
      type: "GridWidget",
      parent: "grpbxPremDeductWRPurch",
      gridCellsOrder:
        "chkbxcol,txtcolPremDeduct,txtcolPamount,txtcolSamount,txtcolVendor1,txtcolVendor2,txtcolVendor3,txtcolVendor4,txtcolAmt1,txtcolAmt2,txtcolAmt3,txtcolAmt4,txtcolPct1,txtcolPct2,txtcolPct3,txtcolPct4,txtcolPdCode,txtcolPdInd,txtcolRateType,txtcolAutoInd,txtcolInspectInd,txtcolRequiredInd,txtcolBeforeRecvInd,txtcolLstrVendorsP,txtcolPs1,txtcolPs2,txtcolPs3,txtcolPs4,txtcolOrigin,txtIStatus",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      isCobolTable: true,
      isEditable: true,
      toggleRender: true,
      conditionalRowStyles: [
        {
          when: (row) => { return checkIfAtleastOneTrue(row) },
          style: (row) => (setStyles(row))
        },
      ],

      // END_USER_CODE-USER_gridPremDeductWRP_PROPERTIES
    },
    lblPremDeduct: {
      name: "lblPremDeduct",
      type: "LabelWidget",
      parent: "grpbxPremDeductWRPurch",
      Label: "Premiums/Deductions:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPremDeduct_PROPERTIES

      // END_USER_CODE-USER_lblPremDeduct_PROPERTIES
    },
    txtcolAmt1: {
      name: "txtcolAmt1",
      type: "TextBoxWidget",
      colName: "colAmt1",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolAmt1_PROPERTIES

      // END_USER_CODE-USER_txtcolAmt1_PROPERTIES
    },
    txtcolAmt2: {
      name: "txtcolAmt2",
      type: "TextBoxWidget",
      colName: "colAmt2",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolAmt2_PROPERTIES

      // END_USER_CODE-USER_txtcolAmt2_PROPERTIES
    },
    txtcolAmt3: {
      name: "txtcolAmt3",
      type: "TextBoxWidget",
      colName: "colAmt3",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolAmt3_PROPERTIES

      // END_USER_CODE-USER_txtcolAmt3_PROPERTIES
    },
    txtcolAmt4: {
      name: "txtcolAmt4",
      type: "TextBoxWidget",
      colName: "colAmt4",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolAmt4_PROPERTIES

      // END_USER_CODE-USER_txtcolAmt4_PROPERTIES
    },
    txtcolAutoInd: {
      name: "txtcolAutoInd",
      type: "TextBoxWidget",
      colName: "colAutoInd",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolAutoInd_PROPERTIES

      // END_USER_CODE-USER_txtcolAutoInd_PROPERTIES
    },
    txtcolBeforeRecvInd: {
      name: "txtcolBeforeRecvInd",
      type: "TextBoxWidget",
      colName: "colBeforeRecvInd",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolBeforeRecvInd_PROPERTIES

      // END_USER_CODE-USER_txtcolBeforeRecvInd_PROPERTIES
    },
    txtcolInspectInd: {
      name: "txtcolInspectInd",
      type: "TextBoxWidget",
      colName: "colInspectInd",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolInspectInd_PROPERTIES

      // END_USER_CODE-USER_txtcolInspectInd_PROPERTIES
    },
    txtcolLstrVendorsP: {
      name: "txtcolLstrVendorsP",
      type: "TextBoxWidget",
      colName: "colLstrVendorsP",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolLstrVendorsP_PROPERTIES

      // END_USER_CODE-USER_txtcolLstrVendorsP_PROPERTIES
    },
    txtcolOrigin: {
      name: "txtcolOrigin",
      type: "TextBoxWidget",
      colName: "colOrigin",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolOrigin_PROPERTIES

      // END_USER_CODE-USER_txtcolOrigin_PROPERTIES
    },
    txtcolPamount: {
      name: "txtcolPamount",
      type: "TextBoxWidget",
      colName: "colPamount",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // ReadOnly: true
      // START_USER_CODE-USER_txtcolPamount_PROPERTIES

      // END_USER_CODE-USER_txtcolPamount_PROPERTIES
    },
    txtcolPct1: {
      name: "txtcolPct1",
      type: "TextBoxWidget",
      colName: "colPct1",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPct1_PROPERTIES

      // END_USER_CODE-USER_txtcolPct1_PROPERTIES
    },
    txtcolPct2: {
      name: "txtcolPct2",
      type: "TextBoxWidget",
      colName: "colPct2",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPct2_PROPERTIES

      // END_USER_CODE-USER_txtcolPct2_PROPERTIES
    },
    txtcolPct3: {
      name: "txtcolPct3",
      type: "TextBoxWidget",
      colName: "colPct3",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPct3_PROPERTIES

      // END_USER_CODE-USER_txtcolPct3_PROPERTIES
    },
    txtcolPct4: {
      name: "txtcolPct4",
      type: "TextBoxWidget",
      colName: "colPct4",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPct4_PROPERTIES

      // END_USER_CODE-USER_txtcolPct4_PROPERTIES
    },
    txtcolPdInd: {
      name: "txtcolPdInd",
      type: "TextBoxWidget",
      colName: "colPdInd",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPdInd_PROPERTIES

      // END_USER_CODE-USER_txtcolPdInd_PROPERTIES
    },
    txtcolPremDeduct: {
      name: "txtcolPremDeduct",
      type: "TextBoxWidget",
      colName: "colPremDeduct",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPremDeduct_PROPERTIES

      // END_USER_CODE-USER_txtcolPremDeduct_PROPERTIES
    },
    txtcolPs1: {
      name: "txtcolPs1",
      type: "TextBoxWidget",
      colName: "colPs1",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPs1_PROPERTIES

      // END_USER_CODE-USER_txtcolPs1_PROPERTIES
    },
    txtcolPs2: {
      name: "txtcolPs2",
      type: "TextBoxWidget",
      colName: "colPs2",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPs2_PROPERTIES

      // END_USER_CODE-USER_txtcolPs2_PROPERTIES
    },
    txtcolPs3: {
      name: "txtcolPs3",
      type: "TextBoxWidget",
      colName: "colPs3",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPs3_PROPERTIES

      // END_USER_CODE-USER_txtcolPs3_PROPERTIES
    },
    txtcolPs4: {
      name: "txtcolPs4",
      type: "TextBoxWidget",
      colName: "colPs4",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolPs4_PROPERTIES

      // END_USER_CODE-USER_txtcolPs4_PROPERTIES
    },
    txtcolRateType: {
      name: "txtcolRateType",
      type: "TextBoxWidget",
      colName: "colRateType",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolRateType_PROPERTIES

      // END_USER_CODE-USER_txtcolRateType_PROPERTIES
    },
    txtcolRequiredInd: {
      name: "txtcolRequiredInd",
      type: "TextBoxWidget",
      colName: "colRequiredInd",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolRequiredInd_PROPERTIES

      // END_USER_CODE-USER_txtcolRequiredInd_PROPERTIES
    },
    txtcolSamount: {
      name: "txtcolSamount",
      type: "TextBoxWidget",
      colName: "colSamount",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolSamount_PROPERTIES

      // END_USER_CODE-USER_txtcolSamount_PROPERTIES
    },
    txtcolVendor1: {
      name: "txtcolVendor1",
      type: "TextBoxWidget",
      colName: "colVendor1",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolVendor1_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor1_PROPERTIES
    },
    txtcolVendor2: {
      name: "txtcolVendor2",
      type: "TextBoxWidget",
      colName: "colVendor2",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolVendor2_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor2_PROPERTIES
    },
    txtcolVendor3: {
      name: "txtcolVendor3",
      type: "TextBoxWidget",
      colName: "colVendor3",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolVendor3_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor3_PROPERTIES
    },
    txtcolVendor4: {
      name: "txtcolVendor4",
      type: "TextBoxWidget",
      colName: "colVendor4",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolVendor4_PROPERTIES

      // END_USER_CODE-USER_txtcolVendor4_PROPERTIES
    },
    txtcolPdCode: {
      name: "txtcolPdCode",
      type: "TextBoxWidget",
      colName: "colPdCode",
      parent: "gridPremDeductWRP",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      ReadOnly: true
      // START_USER_CODE-USER_txtcolPdCode_PROPERTIES

      // END_USER_CODE-USER_txtcolPdCode_PROPERTIES
    },
    grpbxPremDeductWRPurch: {
      name: "grpbxPremDeductWRPurch",
      type: "GroupBoxWidget",
      parent: "PremiumDeductionsWRPurch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPremDeductWRPurch_PROPERTIES

      // END_USER_CODE-USER_grpbxPremDeductWRPurch_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "PremiumDeductionsWRPurch",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
    _kaledo.thisObj = thisObj
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    FormLoad()
    setData(thisObj, 'frmViewWarehouseReceiptFormReLoad', { flag : true })
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    const val = getData(thisObj, 'closingOfReportPreviewWR');
    if (val) {
      setData(thisObj, 'frmPremiumDeductionsWRPurchOKFlag', { flag: true })
      setData(thisObj, 'frmViewWarehouseReceiptFormReLoad', null)
      document.getElementById("WarehouseReceipts_PremiumDeductionsWRPurchPopUp").childNodes[0].click()
      setData(thisObj, 'closingOfReportPreviewWR', null)
    }
  }, [getData(thisObj, 'closingOfReportPreviewWR')]);

  //global variable
  let apiResp = []
  let lblPSEGValue

  // START_USER_CODE-USER_METHODS
  function FormLoad() {
    FillPremiumsDeductions();
    let prntData = getData(thisObj, 'frmPDWRPurchData')

    if (prntData.msMode == 'PURCHASE') {
      ReadPremiumsDeductions();
      hide(thisObj, 'btnOk')
    } else if (prntData.msMode == 'TENTATIVE') {
      ReadPremiumsDeductionsTentativeAppl();
      hide(thisObj, 'btnPurchase')
    }
  }

  function FillPremiumsDeductions() {
    try {
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolVendor1')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolVendor2')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolVendor3')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolVendor4')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolAmt1')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolAmt2')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolAmt3')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolAmt4')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolPct1')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolPct2')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolPct3')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolPct4')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolPdCode')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolPdInd')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolRateType')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolAutoInd')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolInspectInd')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolRequiredInd')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolBeforeRecvInd')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolLstrVendorsP')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolPs1')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolPs2')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolPs3')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolPs4')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolOrigin')
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolSamount') //vsfData.ColWidth(4) = 0
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolOrigin') //vsfData.ColWidth(32) = 0
      hideColumn(thisObj, 'gridPremDeductWRP', 'txtcolVendor1')
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message)
      }
      else {
        showMessage(thisObj, "Error occurred in FillPremiumsDeductions method")
      }
    }
  }

  function checkIfAtleastOneTrue(row) {
    let check = chkboxEnableDisableIND(row) || row.txtcolRateType != 'ENTRY'
    return check
  }

  function chkboxEnableDisableIND(row) {
    let ress = apiResp.filter((obj) => obj.required_ind == row.txtcolRequiredInd)

    if (ress[0].required_ind == 'Y') {
      return true
    } else {
      return false
    }
  }

  function setStyles(row) {
    let style =
      chkboxEnableDisableIND(row) && row.txtcolRateType != 'ENTRY' ?
        {
          backgroundColor: 'lightgray',
          "input[type='checkbox']": {
            pointerEvents: "none",
          },
          "input[type='text']": {
            pointerEvents: "none",
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
          },
        } : chkboxEnableDisableIND(row) && row.txtcolRateType != 'ENTRY' ?
          {
            backgroundColor: 'lightgray',
            "input[type='checkbox']": {
              pointerEvents: "none",
            },
            "input[type='text']": {
              pointerEvents: "none",
              border: 'none',
              boxShadow: 'none',
              background: 'transparent',
            },
          }
          : row.txtcolRateType != 'ENTRY' ?
            {
              "input[type='text']": {
                pointerEvents: "none",
                border: 'none',
                boxShadow: 'none',
                background: 'transparent',
              },
            } : chkboxEnableDisableIND(row) ?
              {
                backgroundColor: 'lightgray',
                "input[type='checkbox']": {
                  pointerEvents: "none",
                },
              } : {}
    return style
  }

  const GetPremiumDeductSign = (strValue, intRow) => {
    let strPermDeduct
    if (strValue == '0.00' || strValue == '0') {
      return parseFloat(strValue)
    }

    if (strValue == '') {
      return '0'
    } else if (isNaN(strValue)) {
      return '0'
    }

    strPermDeduct = getGridValue(thisObj, 'gridPremDeductWRP', 'txtcolPdInd', intRow)
    switch (strPermDeduct) {
      case 'D':
        if (strValue.slice(0, 1) == '-') {
          return parseFloat(strValue)
        } else {
          if (strValue.slice(0, 1) == '+') {
            return parseFloat(strValue)
          } else {
            return '-' + strValue
          }
        }
        break;
      case "P":
        return parseFloat(strValue)
        break;
      default:
        break;
    }
  }

  const ontxtcolPamountBlur = (event) => {
    let nameEvent = event.target.name
    let arr = nameEvent.split(".")[1]
    let editedValue = GetPremiumDeductSign(event.target.value, arr)
    setGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'txtcolPamount', arr, editedValue)

    let val1 = getGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'txtcolPamount', arr) * (getGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'txtcolPct1', arr) * 0.01)
    setGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'txtcolAmt1', arr, val1)

    let val2 = getGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'txtcolPamount', arr) * (getGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'txtcolPct2', arr) * 0.01)
    setGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'txtcolAmt2', arr, val2)

    let val3 = getGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'txtcolPamount', arr) * (getGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'txtcolPct3', arr) * 0.01)
    setGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'txtcolAmt3', arr, val3)

    let val4 = getGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'txtcolPamount', arr) * (getGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'txtcolPct4', arr) * 0.01)
    setGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'txtcolAmt4', arr, val4)

    _kaledo.thisObj.setState({
      ...thisObj.state,
      gridPremDeductWRP: {
        ...state.gridPremDeductWRP,
        toggleRender: !state.gridPremDeductWRP.toggleRender
      }
    })
    FillPremiumsDeductions()

  }
  thisObj.ontxtcolPamountBlur = ontxtcolPamountBlur;


  function ReadPremiumsDeductions() {
    try {
      let prntData = getData(thisObj, 'frmPDWRPurchData')
      let wr_recpt_num = prntData.lblWRNum
      let obl_buy_pt_id = prntData.MstrOBUY_PT_ID
      let unld_buy_pt_id = prntData.sInspBuyPtId
      let inspect_ind = prntData.LstrInspectInd
      lblPSEGValue = prntData.lblFarmerStockCost
      //let insp_num = prntData.LstrInspNum
      let LstrNewInspNum = ""
      if (prntData.sInspList.split(',') != null && prntData.sInspList.split(',') != undefined && prntData.sInspList.split(',').length > 0) {
        for (let i = 0; i < prntData.sInspList.split(',').length; i++) {
          if (prntData.sInspList.split(',')[i].split('|') != null && prntData.sInspList.split(',')[i].split('|') != undefined && prntData.sInspList.split(',')[i].split('|').length > 0) {
            LstrNewInspNum = LstrNewInspNum + prntData.sInspList.split(',')[i].split('|')[0] + ","
          }
        }
      }
      let segvalue_p = prntData.lblFarmerStockCost
      let gmasegvalue_p = prntData.dblGMASeg
      let contlist_p = prntData.sApplContractList
      let appllbs_p = prntData.sAppliedList
      let vendlist_p = prntData.lblRcptVendor
      let netwt_p = prntData.dblNetWtP
      let lskwt_p = prntData.dblLSKWtP
      let grosswt_p = prntData.dblGrossWtP
      let segvalue_s = prntData.LdblSegValue_S
      let contlist_s = prntData.LstrContract_S
      let appllbs_s = prntData.LstrApplyLBS_S
      let vendlist_s = prntData.LstrVendors_S
      let netwt_s = prntData.LdblNET_WT_S
      let lskwt_s = prntData.LdblLSK_WT_S
      let grosswt_s = prntData.LdblGROSS_WT_S
      let basis_grade_amt_p = prntData.lblValPerTon
      let basis_grade_amt_s = prntData.LdblBASIS_GRADE_AMT_S
      let dma_vendor = prntData.txtDMAVendor === 0 ? '' : prntData.txtDMAVendor
      let contractlbs1007 = prntData.s1007ContractPoundsLst
      let contlist_seed = prntData.sSeedContractList
      let appllbs_seed = prntData.sSeedList

      WarehouseReceiptService.RetrievePremiumDeductionScheduleList(wr_recpt_num, obl_buy_pt_id, unld_buy_pt_id, inspect_ind, LstrNewInspNum, segvalue_p, gmasegvalue_p, contlist_p, appllbs_p, vendlist_p, netwt_p, lskwt_p, grosswt_p, segvalue_s, contlist_s, appllbs_s, vendlist_s, netwt_s, lskwt_s, grosswt_s, basis_grade_amt_p, basis_grade_amt_s, dma_vendor, contractlbs1007, contlist_seed, appllbs_seed).then(response => {
        let PremiumsDeductionsArray = [];
        apiResp = response
        if (response != undefined && response.length > 0) {
          let data = response;
          let obj = {};
          for (let i = 0; i < data.length; i++) {
            obj.txtcolPremDeduct = data[i].pd_desc
            obj.txtcolPamount = Number(data[i].pamount).toFixed(2)
            obj.txtcolSamount = data[i].samount
            obj.txtcolVendor1 = data[i].vendor1
            obj.txtcolVendor2 = data[i].vendor2
            obj.txtcolVendor3 = data[i].vendor3
            obj.txtcolVendor4 = data[i].vendor4
            obj.txtcolAmt1 = (Number(data[i].amt1)).toFixed(0)
            obj.txtcolAmt2 = (Number(data[i].amt2)).toFixed(0)
            obj.txtcolAmt3 = (Number(data[i].amt3)).toFixed(0)
            obj.txtcolAmt4 = (Number(data[i].amt4)).toFixed(0)
            obj.txtcolPct1 = data[i].pct1
            obj.txtcolPct2 = data[i].pct2
            obj.txtcolPct3 = data[i].pct3
            obj.txtcolPct4 = data[i].pct4
            obj.txtcolPdCode = data[i].pd_code
            obj.txtcolPdInd = data[i].pd_ind
            obj.txtcolRateType = data[i].rate_type
            obj.txtcolAutoInd = data[i].auto_ind
            obj.txtcolInspectInd = data[i].inspect_ind
            obj.txtcolRequiredInd = data[i].required_ind
            obj.txtcolBeforeRecvInd = data[i].before_recv_ind
            obj.txtcolPs1 = data[i].ps1
            obj.txtcolPs2 = data[i].ps2
            obj.txtcolPs3 = data[i].ps3
            obj.txtcolPs4 = data[i].ps4
            obj.txtcolOrigin = data[i].insp_num
            if (data[i].required_ind != null && data[i].required_ind != undefined && data[i].required_ind === "Y") {
              obj.chkbxcol = true
            }

            if (data[i].auto_ind != null && data[i].auto_ind != undefined && data[i].auto_ind === "Y") {
              obj.chkbxcol = true
            }

            if (data[i].rate_type != null && data[i].rate_type != undefined && data[i].rate_type === "ENTRY") {
              obj.txtcolPamount = data[i].base_rate
            }
            obj.txtcolLstrVendorsP = prntData.LstrVendors_P

            PremiumsDeductionsArray.push(obj)
            obj = {}
          }
          setValue(thisObj, "gridPremDeductWRP", PremiumsDeductionsArray);
        }
      })
    }
    catch (err) {
      errorHandler(err, "ReadPremiumsDeductions")
    }
    finally {
      // setLoading(false)
    }
  }

  //   let dataForReadPremiumsDeductionsMethodObj = {}
  //   dataForReadPremiumsDeductionsMethodObj.sInspList = "2627644|Y|19660|3595.1|2627644341495|358.31|100|9030.17|3595.1,2627643|Y|19174|3443.86|2627643341495|358.31|100|8650.29|3443.86,2627642|Y|20077|3775.1|2627642341495|358.31|100|9482.30|3775.1,2627640|Y|20714|3573.88|2627640341495|358.31|100|8976.87|3573.88,2627645|N|20186|3439.48|2627645341495|358.31|100|8639.29|3439.48,2627646|N|20771|3596.26|2627646341495|358.31|100|9033.09|3596.26"
  //   dataForReadPremiumsDeductionsMethodObj.sInspBuyPtId = "R91"
  //   dataForReadPremiumsDeductionsMethodObj.LstrInspectInd = "N" //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsMethodObj.s1007ContractPoundsLst = "2627644,P,341495,2,19660,Y|2627643,P,341495,2,19174,Y|2627642,P,341495,2,20077,Y|2627640,P,341495,2,20714,Y|2627645,P,341495,2,20186,N|2627646,P,341495,2,20771,N|"
  //   dataForReadPremiumsDeductionsMethodObj.lblFarmerStockCost = 53812.01
  //   dataForReadPremiumsDeductionsMethodObj.dblGMASeg = 21423.68
  //   dataForReadPremiumsDeductionsMethodObj.sApplContractList = "341495"
  //   dataForReadPremiumsDeductionsMethodObj.sAppliedList = "120582"
  //   dataForReadPremiumsDeductionsMethodObj.lblRcptVendor = "658877" // Set only vendor number not vendor namber and name - VB code is setting lblRcptVendor.Tag
  //   dataForReadPremiumsDeductionsMethodObj.dblNetWtP = 120582
  //   dataForReadPremiumsDeductionsMethodObj.dblLSKWtP = 1474 
  //   dataForReadPremiumsDeductionsMethodObj.dblGrossWtP = 126200
  //   dataForReadPremiumsDeductionsMethodObj.LdblSegValue_S = 0 //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsMethodObj.LstrContract_S = "" //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsMethodObj.LstrApplyLBS_S = "" //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsMethodObj.LstrVendors_S = "" //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsMethodObj.LdblNET_WT_S = 0 //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsMethodObj.LdblLSK_WT_S = 0 //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsMethodObj.LdblGROSS_WT_S = 0 //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsMethodObj.lblValPerTon = 358.31
  //   dataForReadPremiumsDeductionsMethodObj.LdblBASIS_GRADE_AMT_S = 0 //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsMethodObj.txtDMAVendor = ""
  //   dataForReadPremiumsDeductionsMethodObj.lblWRNum = "048878"
  //   dataForReadPremiumsDeductionsMethodObj.sSeedContractList = ""
  //   dataForReadPremiumsDeductionsMethodObj.sSeedList = ""
  //   dataForReadPremiumsDeductionsMethodObj.MstrOBUY_PT_ID = "T60" //Set value of frmViewWarehouseReceipt.lblBuyingPtId.Tag
  //   setData(thisObj, "dataForReadPremiumsDeductionsMethod", dataForReadPremiumsDeductionsMethodObj);


  function ReadPremiumsDeductionsTentativeAppl() {
    try {
      let prntData = getData(thisObj, 'frmPDWRPurchData')

      let wr_recpt_num = prntData.lblWRNum
      let obl_buy_pt_id = prntData.MstrOBUY_PT_ID
      let unld_buy_pt_id = prntData.sInspBuyPtId
      let insp_num = prntData.sInspList
      let LstrNewInspNum = ""
      if (prntData.sInspList.split(',') != null && prntData.sInspList.split(',') != undefined && prntData.sInspList.split(',').length > 0) {
        for (let i = 0; i < prntData.sInspList.split(',').length; i++) {
          if (prntData.sInspList.split(',')[i].split('|') != null && prntData.sInspList.split(',')[i].split('|') != undefined && prntData.sInspList.split(',')[i].split('|').length > 0) {
            LstrNewInspNum = LstrNewInspNum + prntData.sInspList.split(',')[i].split('|')[0] + ","
          }
        }
      }


      let inspect_ind = prntData.LstrInspectInd
      let segvalue_p = 0
      let gmasegvalue_p = 0
      let contlist_p = prntData.LstrContract_S
      let appllbs_p = prntData.LstrApplyLBS_S
      let vendlist_p = prntData.LstrVendors_S
      let netwt_p = prntData.LdblNET_WT_S
      let lskwt_p = prntData.LdblLSK_WT_S
      let grosswt_p = prntData.LdblGROSS_WT_S
      let segvalue_s = prntData.lblValOfSeg
      let contlist_s = prntData.sApplContractList
      let appllbs_s = prntData.sTentativeList
      let vendlist_s = prntData.lblRcptVendor
      let netwt_s = prntData.dblNetWt_S
      let lskwt_s = prntData.dblLSKWt_S
      let grosswt_s = prntData.dblGrossWt_S
      let basis_grade_amt_p = prntData.LdblBASIS_GRADE_AMT_S
      let basis_grade_amt_s = prntData.dblGMASeg
      let dma_vendor = prntData.txtDMAVendor
      let contractlbs1007 = prntData.s1007ContractPoundsLst
      let contlist_seed = prntData.sSeedContractList
      let appllbs_seed = prntData.sSeedList


      WarehouseReceiptService.RetrievePremiumDeductionScheduleDetails(wr_recpt_num, obl_buy_pt_id, unld_buy_pt_id, LstrNewInspNum, inspect_ind, segvalue_p, gmasegvalue_p, contlist_p,
        appllbs_p, vendlist_p, netwt_p, lskwt_p, grosswt_p, segvalue_s, contlist_s, appllbs_s, vendlist_s, netwt_s, lskwt_s, grosswt_s, basis_grade_amt_p,
        basis_grade_amt_s, dma_vendor, contractlbs1007, contlist_seed, appllbs_seed).then(response => {
          let PremiumsDeductionsArray = [];
          apiResp = response
          if (response != undefined && response.length > 0) {
            let data = response;
            let obj = {};
            for (let i = 0; i < data.length; i++) {
              obj.txtcolPremDeduct = data[i].pd_desc
              obj.txtcolPamount = Number(data[i].samount).toFixed(2) // In VB Code, samount values are shown in 03rd Column i.e txtcolPamount
              //obj.txtcolSamount = data[i].samount // In VB Code, values are not assigned to 04th column i.e txtcolSamount
              obj.txtcolVendor1 = data[i].vendor1
              obj.txtcolVendor2 = data[i].vendor2
              obj.txtcolVendor3 = data[i].vendor3
              obj.txtcolVendor4 = data[i].vendor4
              obj.txtcolAmt1 = data[i].amt1
              obj.txtcolAmt2 = data[i].amt2
              obj.txtcolAmt3 = data[i].amt3
              obj.txtcolAmt4 = data[i].amt4
              obj.txtcolPct1 = data[i].pct1
              obj.txtcolPct2 = data[i].pct2
              obj.txtcolPct3 = data[i].pct3
              obj.txtcolPct4 = data[i].pct4
              obj.txtcolPdCode = data[i].pd_code
              obj.txtcolPdInd = data[i].pd_ind
              obj.txtcolRateType = data[i].rate_type
              obj.txtcolAutoInd = data[i].auto_ind
              obj.txtcolInspectInd = data[i].inspect_ind
              obj.txtcolRequiredInd = data[i].required_ind
              obj.txtcolBeforeRecvInd = data[i].before_recv_ind
              obj.txtcolPs1 = data[i].ps1
              obj.txtcolPs2 = data[i].ps2
              obj.txtcolPs3 = data[i].ps3
              obj.txtcolPs4 = data[i].ps4
              obj.txtcolOrigin = data[i].insp_num
              if (data[i].required_ind != null && data[i].required_ind != undefined && data[i].required_ind === "Y") {
                obj.chkbxcol = true
                // vsfData.Cell(flexcpBackColor, vsfData.Rows - 1, 0) = &HE0E0E0
                // vsfData.Cell(flexcpBackColor, vsfData.Rows - 1, 1) = &HE0E0E0
                // vsfData.Cell(flexcpBackColor, vsfData.Rows - 1, 2) = &HE0E0E0
                // vsfData.Cell(flexcpBackColor, vsfData.Rows - 1, 3) = &HE0E0E0
                // vsfData.Cell(flexcpBackColor, vsfData.Rows - 1, 4) = &HE0E0E0
              }

              if (data[i].auto_ind != null && data[i].auto_ind != undefined && data[i].auto_ind === "Y") {
                obj.chkbxcol = true
              }

              if (data[i].rate_type != null && data[i].rate_type != undefined && data[i].rate_type === "ENTRY") {
                obj.txtcolPamount = data[i].base_rate
              }
              obj.txtcolLstrVendorsP = ''

              PremiumsDeductionsArray.push(obj)
              obj = {}
            }
            setValue(thisObj, "gridPremDeductWRP", PremiumsDeductionsArray);
          }
        })
    }
    catch (err) {
      errorHandler(err, "ReadPremiumsDeductionsTentativeAppl")
    }
    finally {
      // setLoading(false)
    }
  }


  //   let dataForReadPremiumsDeductionsTentativeApplMethodObj = {}
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.sInspList = "G013776|N|12550|2072.44|295.32|83.26|1725.51,G013799|N|11865|1917.71|295.32|83.26|1596.69,G013820|N|11251|1799.19|295.32|83.26|1498.01,G013822|N|12324|2009.46|295.32|83.26|1673.08,G013823|N|7261|1218.93|295.32|83.26|1014.88,G013797|N|5111|780.42|295.32|83.26|649.78,G013824|N|11785|1932.18|295.32|83.26|1608.73,G013858|N|4750|771.63|295.32|83.26|642.46"
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.sInspBuyPtId = "R33"
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.LstrInspectInd = "N" //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.LdblSegValue_P = 0 //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.LdblGMASegValue_P = 0 //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.LstrContract_P = "" //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.LstrApplyLBS_P = "" //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.LstrVendors_P = "" //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.LdblNET_WT_P = 0 //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.LdblLSK_WT_P = 0 //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.LdblGROSS_WT_P = 0 //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.lblValOfSeg = 10409.14
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.sApplContractList = 341882
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.sTentativeList = 76897
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.lblRcptVendorTag = 206109
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.dblNetWt_S = 76897
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.dblLSKWt_S = 3706
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.dblGrossWt_S = 82940
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.LdblBASIS_GRADE_AMT_P = 0 //This is hardcoded value in frmViewWarehouseReceipt, so set as it is
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.dblGMASeg = 12501.96
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.txtDMAVendor = ""
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.lblWRNum = 203149
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.s1007ContractPoundsLst = "G013776,S,341882,2,12550,N|G013799,S,341882,2,11865,N|G013820,S,341882,2,11251,N|G013822,S,341882,2,12324,N|G013823,S,341882,2,7261,N|G013797,S,341882,2,5111,N|G013824,S,341882,2,11785,N|G013858,S,341882,2,4750,N|"
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.sSeedContractList = ""
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.sSeedList = ""
  //   dataForReadPremiumsDeductionsTentativeApplMethodObj.MstrOBUY_PT_ID = "R01" //Set value of frmViewWarehouseReceipt.lblBuyingPtId.Tag
  //   setData(thisObj, "dataForReadPremiumsDeductionsTentativeApplMethod", dataForReadPremiumsDeductionsTentativeApplMethodObj);

  //function for Cancel button click event
  const onbtnCancelClick = () => {
    try {
      if (document.getElementById("WarehouseReceipts_PremiumDeductionsWRPurchPopUp") != null && document.getElementById("WarehouseReceipts_PremiumDeductionsWRPurchPopUp").childNodes[0] != undefined && document.getElementById("WarehouseReceipts_PremiumDeductionsWRPurchPopUp").childNodes[0] != null) {
        setData(thisObj, 'frmViewWarehouseReceiptFormReLoad', { flag : true })
        document.getElementById("WarehouseReceipts_PremiumDeductionsWRPurchPopUp").childNodes[0].click()
      }
    }
    catch (err) {
      errorHandler(err, "onbtnCancelClick")
    }
    finally {
      // setLoading(false)
    }
    return true;
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;



  const onbtnOkClick = async () => {
    try {
      let s = ""
      let nn
      let LdblP1
      let LdblD1
  
      const purchaseWRConfirmation = confirm("Are you sure you want to Tentatively Apply this warehouse receipt?");
      if (purchaseWRConfirmation) {
        LdblP1 = 0
        LdblD1 = 0
        nn = 0
        let gridPremDeductWRPData = getValue(thisObj, "gridPremDeductWRP")
        for (let i = 0; i < gridPremDeductWRPData.length; i++) {
          if (getGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'chkbxcol', i)) {
            if (gridPremDeductWRPData[i].txtcolVendor1 != "") {
              nn = nn + 1
              s = s + gridPremDeductWRPData[i].txtcolPdCode + ","
              s = s + gridPremDeductWRPData[i].txtcolVendor1 + ","
              s = s + "000,"
              s = s + (Number(gridPremDeductWRPData[i].txtcolPct1)).toFixed(2) + "," // Preserve two decimal places
              s = s + (Number(gridPremDeductWRPData[i].txtcolAmt1)).toFixed(2) + "," // Preserve two decimal places
              s = s + "0,"
              s = s + ","
              s = s + ","
              s = s + gridPremDeductWRPData[i].txtcolRateType + ","
              s = s + gridPremDeductWRPData[i].txtcolPs1 + ","
              s = s + gridPremDeductWRPData[i].txtcolOrigin + "|"
              if (gridPremDeductWRPData[i].txtcolPdInd == "P" && gridPremDeductWRPData[i].txtcolPs1 == "S") {
                LdblP1 = LdblP1 + parseFloat(gridPremDeductWRPData[i].txtcolAmt1); // Accumulate with decimals
              }
              if (gridPremDeductWRPData[i].txtcolPdInd == "D" && gridPremDeductWRPData[i].txtcolPs1 == "S") {
                LdblD1 = LdblD1 + parseFloat(gridPremDeductWRPData[i].txtcolAmt1) // Accumulate with decimals
              }
            }
          }
        }
  
        let pData1 = getData(thisObj, 'frmPDWRPurchData')
        let pData2 = getData(thisObj, 'frmPremiumDeductionsWRTentJs')
  //PRB0054142_INC5902278 - Fix for Warehouse Receipt - Premiums/Deductions not calculating correctly
        let dataObj = {}
        dataObj.comp_id = compIdFromLS
        dataObj.crop_year = cropYearFromLS
        dataObj.unld_buy_pt_id = pData1.sInspBuyPtId
        dataObj.obl_buy_pt_id = pData1.MstrOBUY_PT_ID
        dataObj.whse_rcpt_num = pData2.MstrWHSE_RCPT_NUM
        dataObj.whouse_num = pData2.MstrWHOUSE_NUM
        dataObj.bin_num = pData2.MstrBIN_NUM
        dataObj.net_wt = pData2.MlngNET_WT
        dataObj.overide_farm = pData2.MstrOVERIDE_FARM
        dataObj.split_vendor = pData1.lblRcptVendor
        dataObj.premium_amt = LdblP1.toFixed(2).toString()  // Ensure two decimal places
        dataObj.deduct_amt = LdblD1.toFixed(2).toString()  // Ensure two decimal places
        dataObj.proceeds_amt = ((Number(pData2.lblOptionPayment)) + (LdblP1 + LdblD1)).toFixed(2).toString(); // Ensure two decimal places
        dataObj.rcpt_values = pData2.MstrRCPT_VALUES //'3655644,50.00,14.52,441.91|3655625,50.00,14.52,387.57|3655627,50.00,14.52,325.20|' 
        dataObj.pds_master = s
        dataObj.cas_master = pData2.MstrCAS_MASTER
        dataObj.sga_master = pData2.MstrSGA_MASTER
        dataObj.uid = sessionStorage.getItem('userid')
        dataObj.option_payment = pData2.lblOptionPayment
        dataObj.cont_appls = pData2.MstrCONT_APPLS // '351806,2,17403,17403,0,3655644|351806,2,15446,15446,0,3655625|351806,2,12959,0,12959,3655627|' //pData2.MstrCONT_APPLS
  
        if (!['', undefined, null].includes(pData2.txtEndDate)) {
          dataObj.chrg_end_date = pData2.txtEndDate
        } 
        else {
          dataObj.chrg_end_date = (new Date().toISOString()).split('T')[0]
          // txtEndDate =  CHRG_END_DATE (need to set data for harshit's screen)
        }
  
        let resp = await WarehouseReceiptService.UpdateSettleTentApplication(dataObj)
        if (resp != null && resp != [] && resp != undefined && resp.status == 200) {
          setData(thisObj, 'frmPremiumDeductionsWRPurchOKFlag', { flag: true })
          setData(thisObj, 'frmViewWarehouseReceiptFormReLoad', null)
  
          if (!confirm(`This Warehouse Receipt has been Tentatively Applied!!!`)) {
            document.getElementById("WarehouseReceipts_PremiumDeductionsWRPurchPopUp").childNodes[0].click()
          } else {
            document.getElementById("WarehouseReceipts_PremiumDeductionsWRPurchPopUp").childNodes[0].click()
          }
        } else {
          return
        }
      }
    } 
    catch (err) {
      errorHandler(err, "onbtnOkClick")
    } 
    finally {
      // setLoading(false)
    }
    return true;
  };
  thisObj.onbtnOkClick = onbtnOkClick;


  const onbtnPurchaseClick = async () => {
    try {
      let s = ""
      let nn
      let LdblP1
      let LdblD1
      let strWRPuchMsg = ''

      const purchaseWRConfirmation = confirm("Are you sure you want to purchase this warehouse receipt?  If you click YES, it will be automatically purchased.");
      if (purchaseWRConfirmation) {
        LdblP1 = 0
        LdblD1 = 0
        nn = 0
        let gridPremDeductWRPData = getValue(thisObj, "gridPremDeductWRP")
        for (let i = 0; i < gridPremDeductWRPData.length; i++) {
          if (getGridValue(_kaledo.thisObj, 'gridPremDeductWRP', 'chkbxcol', i)) {
            if (gridPremDeductWRPData[i].txtcolVendor1 != "") {
              nn = nn + 1
              s = s + gridPremDeductWRPData[i].txtcolPdCode + ","
              s = s + gridPremDeductWRPData[i].txtcolVendor1 + ","
              s = s + "000,"
              s = s + (Number(gridPremDeductWRPData[i].txtcolPct1)).toFixed(0) + ","
              s = s + (Number(gridPremDeductWRPData[i].txtcolAmt1)).toFixed(0) + ","
              s = s + "0,"
              s = s + ","
              s = s + ","
              s = s + gridPremDeductWRPData[i].txtcolRateType + ","
              s = s + gridPremDeductWRPData[i].txtcolPs1 + ","
              s = s + gridPremDeductWRPData[i].txtcolOrigin + "|"
              if (gridPremDeductWRPData[i].txtcolPdInd == "P" && gridPremDeductWRPData[i].txtcolPs1 == "P") {
                // LdblPPremiums = LdblPPremiums + Number(gridPremDeductWRPData[i].txtcolAmt1)
                LdblP1 = LdblP1 + Number(gridPremDeductWRPData[i].txtcolAmt1)
              }
              if (gridPremDeductWRPData[i].txtcolPdInd == "D" && gridPremDeductWRPData[i].txtcolPs1 == "P") {
                // LdblPDeductions = LdblPDeductions + Number(gridPremDeductWRPData[i].txtcolAmt1)
                LdblD1 = LdblD1 + Number(gridPremDeductWRPData[i].txtcolAmt1)
              }
            }
          }
        }


        let pData1 = getData(thisObj, 'frmPDWRPurchData')
        let pData2 = getData(thisObj, 'frmPremiumDeductionsWRPurchJs')

        let msWireData = ""
        msWireData = thisObj.values.txtRepayVendor == '134969'
          ? (thisObj.values.txtWireTransferData?.trim() + thisObj.values.txtWireTransferData2?.trim() + thisObj.values.txtWireTransferData3?.trim() +
             thisObj.values.txtWireTransferData4?.trim() + thisObj.values.txtWireTransferData5?.trim() + thisObj.values.txtWireTransferData6?.trim())
          : thisObj.values.txtRepayVendor == '745134' 
             ? (thisObj.values.txtConcordia1?.trim() + thisObj.values.txtConcordia2?.trim() + thisObj.values.txtConcordia3?.trim())
             : ''
        
        let MstrPDS_MASTER = s
        let MstrRCPT_VALUES = pData2.MstrRCPT_VALUES
        let MstrCAS_MASTER = pData2.MstrCAS_MASTER
        let MstrSGA_MASTER = pData2.MstrSGA_MASTER
        let ms1007ContractPounds = pData1.s1007ContractPoundsLst

        let wire_xfer_ind = "";
        let wire_xfer_data = "";

        let JsonObj = {
          "rcpt_value_count": 0,
          "pds_master_count": 0,
          "cas_master_count": 0,
          "sga_master_count": 0,
          "contract_pound_count": 0,
          pds_masters: [],
          cas_masters: [],
          sga_masters: [],
          rcpt_values: [],
          contract_pounds: [],
        }

        for (let i = 0; i < MstrPDS_MASTER.split("|").length - 1; i++) {
           let pds = {pds_master : MstrPDS_MASTER.split("|")[i]}
           let pds_arr = JsonObj.pds_masters
           pds_arr.push(pds)
          JsonObj = {...JsonObj, pds_master_count : i + 1, pds_masters : pds_arr}
        }

        for (let i = 0; i < MstrCAS_MASTER.split("|").length - 1; i++) {
          let cas = {cas_master : MstrCAS_MASTER.split("|")[i]}
          let cas_arr = JsonObj.cas_masters
           cas_arr.push(cas)
          JsonObj = {...JsonObj, cas_master_count : i + 1, cas_masters : cas_arr}
        }

        for (let i = 0; i < MstrSGA_MASTER.split("|").length - 1; i++) {
          let sga = {sga_master : MstrSGA_MASTER.split("|")[i]}
          let sga_arr = JsonObj.sga_masters
           sga_arr.push(sga)
          JsonObj = {...JsonObj, sga_master_count : i + 1, sga_masters : sga_arr}
        }

        for (let i = 0; i < MstrRCPT_VALUES.split("|").length - 1; i++) {
          let rcpt = {rcpt_value : MstrRCPT_VALUES.split("|")[i]}
          let rcpt_arr = JsonObj.rcpt_values
           rcpt_arr.push(rcpt)
          JsonObj = {...JsonObj, rcpt_value_count : i + 1, rcpt_values : rcpt_arr}
        }

        for (let i = 0; i < ms1007ContractPounds.split("|").length - 1; i++) {
          let contract = {contract_pound : ms1007ContractPounds.split("|")[i]}
          let contract_arr = JsonObj.contract_pounds
           contract_arr.push(contract)
          JsonObj = {...JsonObj, contract_pound_count : i + 1, contract_pounds : contract_arr}
        }

        let MstrOBUY_PT_ID = pData1.MstrOBUY_PT_ID //"2CY"
        let MstrBUY_PT_ID = pData1.sInspBuyPtId // "2CY"
        let MstrWHSE_RCPT_NUM = pData2.MstrWHSE_RCPT_NUM //"049069"
        let MstrWHOUSE_NUM = pData2.MstrWHOUSE_NUM//"001"
        let MstrBIN_NUM = pData2.MstrBIN_NUM //"01"
        let MlngNET_WT = pData2.MlngNET_WT //"34564"
        let MdblSPOT_PRICE = pData2.MdblSPOT_PRICE //"0"
        let MlngSPOT_POUNDS = pData2.MlngSPOT_POUNDS //"0"
        let MstrOVERIDE_FARM = pData2.MstrOVERIDE_FARM //""
        let useridFromLS = sessionStorage.getItem('userid')

        let PREMIUM_AMT = LdblP1
        let DEDUCT_AMT = LdblD1
        let PROCEEDS_AMT
        let CHRG_END_DATE
        let REDEEM_METHOD_IND
        let OPTION_PAYMENT
        let WIRE_XFER_DATA = ""
        let RCPT_VALUE
        let LOAN_PAYOFF_AMT
        let MstrSPLIT_VENDOR = pData1.lblRcptVendor //"789668"
        

        let lblRcptStatus = pData2.lblRcptStatus //""
        let txtRepayAmtOverride = pData2.txtRepayAmtOveride //"0.00"
        let lblShrinkPayment = pData2.lblShrinkPayment //""
        let lblOptionPayment = pData2.lblOptionPayment //"4982.51"
        let txtEndDate = pData2.txtEndDate //"17/11/2023"
        let optRMOrig1007s = pData2.optRMOrig1007s //false
        let optRMReceiptValue = pData2.optRMReceiptValue //false
        let optRMLoanPayoff = pData2.optRMLoanPayoff //false
        let OptUnshrunk = pData2.optUnShrunk //false
        let WireTransferData = pData2.wireTransferData //""
        let lblShrunkRcptValue = pData2.lblShrunkRcptValue //""
        let txtInterestAmt = pData2.txtInterestAmt //""
        let txtStoreCreditAmtOverride = pData2.txtStoreCrAmtOride //""

        if (lblRcptStatus == "Pending Forfeited Purchase") {
          let lblSegValue = txtRepayAmtOverride
          PROCEEDS_AMT = Number(lblSegValue) + (LdblD1 + LdblP1) - Number(lblShrinkPayment)
        }
        else {
          let lblSegValue = pData1.lblFarmerStockCost
          let amt = Number(lblSegValue) - Number(lblOptionPayment) - Number(txtRepayAmtOverride) + (LdblD1 + LdblP1) - Number(lblShrinkPayment)
          if (amt < 0) {
            PROCEEDS_AMT = 0
          } else {
            PROCEEDS_AMT = amt
          }
          // PROCEEDS_AMT == (Number(lblSegValue) - Number(lblOptionPayment) - Number(txtRepayAmtOverride) + (LdblD1 + LdblP1) - Number(lblShrinkPayment)) < 0 ? 0 : (Number(lblSegValue) - Number(lblOptionPayment) - Number(txtRepayAmtOverride) + (LdblD1 + LdblP1) - Number(lblShrinkPayment))
        }

        if (!['', undefined, null].includes(txtEndDate)) {
          CHRG_END_DATE = txtEndDate
        }
        else {
          CHRG_END_DATE = (new Date().toISOString()).split('T')[0]
          // txtEndDate =  CHRG_END_DATE (need to set data for harshit's screen)
        }
        OPTION_PAYMENT = lblOptionPayment

        if (optRMOrig1007s == true) {
          REDEEM_METHOD_IND = "S"

        } else if (optRMReceiptValue == true) {
          REDEEM_METHOD_IND = "R"
        }
        else if (optRMLoanPayoff == true) {
          REDEEM_METHOD_IND = "L"
        }
        else if (OptUnshrunk == true) {
          REDEEM_METHOD_IND = "U"
        }

        if (document.getElementById("chkboxWireTransfer").checked == true) {
          wire_xfer_ind = "Y";
          wire_xfer_data = msWireData;
        }
        else {
          wire_xfer_ind = "N";
          wire_xfer_data = "";
        }
        
        RCPT_VALUE = lblShrunkRcptValue
        LOAN_PAYOFF_AMT = (Number(txtRepayAmtOverride) + Number(txtInterestAmt) + Number(lblOptionPayment) - Number(txtStoreCreditAmtOverride)).toFixed(2)

        let response = await WarehouseReceiptService.RetrieveSettleWhouseReceiptDetails(MstrWHSE_RCPT_NUM, MstrOBUY_PT_ID, MstrBUY_PT_ID, MstrWHOUSE_NUM, MstrBIN_NUM, MlngNET_WT, MdblSPOT_PRICE, MlngSPOT_POUNDS, MstrOVERIDE_FARM, MstrSPLIT_VENDOR, PREMIUM_AMT, DEDUCT_AMT, PROCEEDS_AMT, useridFromLS, CHRG_END_DATE, OPTION_PAYMENT, REDEEM_METHOD_IND, RCPT_VALUE, LOAN_PAYOFF_AMT, WIRE_XFER_DATA, JsonObj)
        if (response != null && response != undefined && response.status == 200) {
          const frmFreightLocToLocSetupDataObj = {
            txtcolStatus: "Purchased"
          }
          setData(thisObj, 'dataComingfromChildScreen', frmFreightLocToLocSetupDataObj);
          strWRPuchMsg = "This Warehouse Receipt Has Been Successfully Purchased!!!"
          // if (response[0]?.contract_num != null) {
          //   // if (response[0].rebate_ready_to_pays != '') {
          //   //   strWRPuchMsg = strWRPuchMsg + '' + "Rebate ready to pays created for the following Pricings:"
          //   //   for (let i = 0; i < array.length; i++) {

          //   //   }
          //   // }

          //   if (!confirm(`${strWRPuchMsg}`)) {
          //     let js = [{
          //       buyingPt: MstrOBUY_PT_ID,
          //       wrRcptNum: MstrWHSE_RCPT_NUM,
          //       rcptValue: MstrWHSE_RCPT_NUM, //pData1.lblRcptValue for time being
          //       docName: 'rptWarehouseReceiptWorksheet'
          //     }]
          //     setData(thisObj, 'ReportPreviewWRData', js)
          //     goTo(thisObj, "WarehouseReceipts#ReportPreviewWR#DEFAULT#true#Click")
          //   } else {
          //     let js = [{
          //       buyingPt: MstrOBUY_PT_ID,
          //       wrRcptNum: MstrWHSE_RCPT_NUM,
          //       rcptValue: MstrWHSE_RCPT_NUM, //pData1.lblRcptValue for time being
          //       docName: 'rptWarehouseReceiptWorksheet'
          //     }]
          //     setData(thisObj, 'ReportPreviewWRData', js)
          //     goTo(thisObj, "WarehouseReceipts#ReportPreviewWR#DEFAULT#true#Click")
          //   }
          // } else if (response[0]?.contract_num == null) {
          //   if (!confirm(`${strWRPuchMsg}`)) {
          //     let js = [{
          //       buyingPt: MstrOBUY_PT_ID,
          //       wrRcptNum: MstrWHSE_RCPT_NUM,
          //       rcptValue: MstrWHSE_RCPT_NUM, //pData1.lblRcptValue for time being
          //       docName: 'rptWarehouseReceiptWorksheet'
          //     }]
          //     setData(thisObj, 'ReportPreviewWRData', js)
          //     goTo(thisObj, "WarehouseReceipts#ReportPreviewWR#DEFAULT#true#Click")
          //   } else {
          //     let js = [{
          //       buyingPt: MstrOBUY_PT_ID,
          //       wrRcptNum: MstrWHSE_RCPT_NUM,
          //       rcptValue: MstrWHSE_RCPT_NUM, //pData1.lblRcptValue for time being
          //       docName: 'rptWarehouseReceiptWorksheet'
          //     }]
          //     setData(thisObj, 'ReportPreviewWRData', js)
          //     goTo(thisObj, "WarehouseReceipts#ReportPreviewWR#DEFAULT#true#Click")
          //   }
          // }

          alert(strWRPuchMsg)

          let js = [{
            buyingPt: MstrOBUY_PT_ID,
            wrRcptNum: MstrWHSE_RCPT_NUM,
            rcptValue: MstrWHSE_RCPT_NUM, //pData1.lblRcptValue for time being
            docName: 'rptWarehouseReceiptWorksheet'
          }]
          
          setData(thisObj, 'ReportPreviewWRData', js)
          goTo(thisObj, "WarehouseReceipts#ReportPreviewWR#DEFAULT#true#Click")
        } 
        else if (response != null && response != undefined && (response[0]?.status || response?.status) == 500) {
          showMessage(thisObj, response?.message, false)
        }
      } else {
        return
      }
    }
    catch (err) {
      errorHandler(err, "onbtnPurchaseClick")
    }
    finally {
      // setLoading(false)
    }
    return true;
  };
  thisObj.onbtnPurchaseClick = onbtnPurchaseClick;

  function errorHandler(err, action) {
    showMessage(thisObj,
      err instanceof EvalError
        ? err.message
        : "Something went wrong for " + action + ". Please try again later.")
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_PremiumDeductionsWRPurch*/}

              {/* END_USER_CODE-USER_BEFORE_PremiumDeductionsWRPurch*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxPremDeductWRPurch*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxPremDeductWRPurch*/}

              <GroupBoxWidget
                conf={state.grpbxPremDeductWRPurch}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_lblPremDeduct*/}

                {/* END_USER_CODE-USER_BEFORE_lblPremDeduct*/}

                <LabelWidget
                  values={values}
                  conf={state.lblPremDeduct}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblPremDeduct*/}

                {/* END_USER_CODE-USER_AFTER_lblPremDeduct*/}
                {/* START_USER_CODE-USER_BEFORE_gridPremDeductWRP*/}

                {/* END_USER_CODE-USER_BEFORE_gridPremDeductWRP*/}

                <GridWidget
                  conf={state.gridPremDeductWRP}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridPremDeductWRP}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  refObject={thisObj}
                  key={state.gridPremDeductWRP.toggleRender}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridPremDeductWRP*/}

                {/* END_USER_CODE-USER_AFTER_gridPremDeductWRP*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxPremDeductWRPurch*/}

              {/* END_USER_CODE-USER_AFTER_grpbxPremDeductWRPurch*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnPurchase*/}

                {/* END_USER_CODE-USER_BEFORE_btnPurchase*/}

                <ButtonWidget
                  conf={state.btnPurchase}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPurchase*/}

                {/* END_USER_CODE-USER_AFTER_btnPurchase*/}
                {/* START_USER_CODE-USER_BEFORE_btnOk*/}

                {/* END_USER_CODE-USER_BEFORE_btnOk*/}

                <ButtonWidget
                  conf={state.btnOk}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnOk*/}

                {/* END_USER_CODE-USER_AFTER_btnOk*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}

              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_PremiumDeductionsWRPurch*/}

              {/* END_USER_CODE-USER_AFTER_PremiumDeductionsWRPurch*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_PremiumDeductionsWRPurch);
